import React from 'react'

function GroupCard({ group, handleDelete }) {
  const baseSiteURL = group.siteURL.replace(/#.*$/, '')

  return (
    <div className="overflow-hidden shadow-outer w-full flex flex-row my-1 p-6">
      <div className="w-8/12 flex flex-col m-auto">
        <span className="font-medium text-base font-sans">{group.name}</span>
        <span className="text-sm font-light">{baseSiteURL}</span>
      </div>
      <div className="w-3/12 flex flex-col m-auto">
        <span className="font-medium text-sm font-sans">{group.siteId}</span>
        <span className="text-sm font-light">Site ID</span>
      </div>
      <div className="w-1/12 flex flex-col m-auto">
        <button
          className="text-red-500 underline"
          onClick={() => handleDelete(group)}
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default GroupCard
