// const AboutPage = () => {
//   return (
//     <div>
//       <h1>AboutPage</h1>
//       <p>Find me in ./web/src/pages/AboutPage/AboutPage.js</p>
//     </div>
//   )
// }

// export default AboutPage

import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'

const AboutPage = () => {
  return (
    <UpdatedMainLayout>
      <div className="text-center">
        <h1 className="text-bold mt-2 pt-4">About Page</h1>
        <p>
          <small>Find me in ./web/src/pages/AboutPage/AboutPage.js</small>
        </p>
      </div>
    </UpdatedMainLayout>
  )
}

export default AboutPage
