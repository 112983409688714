import React from 'react'

import Showdown from 'showdown'

let converter = new Showdown.Converter()

const EmergencyBanner = ({ oldEmergencyBanner, modifiedEmergencyBanner }) => {
  if (oldEmergencyBanner) {
    // convert message to HTML to display in preview.
    let formattedText = converter.makeHtml(oldEmergencyBanner)
    oldEmergencyBanner = formattedText
  }

  if (modifiedEmergencyBanner) {
    // convert message to HTML to display in preview.
    let formattedText = converter.makeHtml(modifiedEmergencyBanner)
    modifiedEmergencyBanner = formattedText
  }

  return (
    <div className="rw-additional-text rw-segment">
      <div className="rw-additional-text__container">
        <div className="rw-additional-text__title">Emergency Banner</div>
        <div className="rw-additional-text__content flex">
          <p className="pr-2">Previous: </p>
          <div
            dangerouslySetInnerHTML={{ __html: oldEmergencyBanner }}
            style={{ textDecoration: 'line-through' }}
          />
        </div>
        <div className="rw-additional-text__content rw-additional-text__content--updated flex">
          <p className="pr-2">New: </p>
          <div dangerouslySetInnerHTML={{ __html: modifiedEmergencyBanner }} />
        </div>
      </div>
    </div>
  )
}

export default EmergencyBanner
