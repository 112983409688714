// styles
import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const LabelStyle = css`
  ${tw`pr-2 text-left`}
`
export const TextFieldStyle = css`
  ${tw`text-center placeholder-black border border-black w-80 h-30`}
`
export const toStyle = css`
  ${tw`text-center placeholder-black border border-black w-80 h-30`}
`
export const ApplyToAllRow = styled.tr`
  ${tw``}
`
export const DayRow = styled.tr`
  ${tw`flex h-45`}
`
export const Monday = styled(DayRow)`
  ${tw`h-30`}
`
export const Schedule = styled.tbody`
  ${tw``}
`
export const SelectStyle = css`
  ${tw`border border-black w-80 h-30`}
`

export const Spacer = css`
  ${tw`px-3 text-transparent`}
`
export const Table = styled.table`
  border-spacing: 25px;
  ${tw`border-collapse border-separate -ml-4`}
`
