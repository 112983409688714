import {useState} from 'react'
import { Input, InputWrapper } from '../UserGroupCell.styles'

const InputSearch = ({setSearchString}) => {
  const [searchValue, setSearchValue] = useState('')
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchString(e.target.value);
  }
  return (
    <InputWrapper>
      <Input
        className="focus:outline-none"
        type="text"
        placeholder="Search sites"
        aria-label="Search"
        onChange ={handleSearch}
        value={searchValue}
      />
    </InputWrapper>
  )
}

export default InputSearch
