import React from 'react'

const AdditionalText = ({ changes, title }) => {
  return (
    <div className="rw-additional-text rw-segment">
      <div className="rw-additional-text__container">
        <div className="rw-additional-text__title">{title}</div>
        <div className="rw-additional-text__content">
          Previous:{' '}
          <span style={{ textDecoration: 'line-through' }}>
            {changes[0].oldValue}
          </span>
        </div>
        <div className="rw-additional-text__content rw-additional-text__content--updated">
          New: {changes[0].newValue}
        </div>
      </div>
    </div>
  )
}

export default AdditionalText
