import * as _ from 'lodash'
/**
 * HTTP request wrapper.
 *
 * @param {string} method HTTP Method
 * @param {Object} data Data
 */
export const request = async (method, data = {}, mutation = false) => {
  const endpoint = `${process.env.EE_REST_ENDPOINT}`

  try {
    let reqObj = {
      method,
      headers: new Headers({
        'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
      }),
    }
    let reqURL = ''
    if (!_.isEmpty(data)) {
      const d = {
        query: data,
      }
      const params = new URLSearchParams(d).toString()
      if (mutation) {
        const query = JSON.stringify({
          query: data,
        })
        reqObj = {
          ...reqObj,
          ...{
            body: query,
          },
        }
        reqURL = endpoint
      } else {
        reqURL = `${endpoint}?${params}`
      }
    }

    const response = await fetch(reqURL, reqObj)
    return await response.json()
  } catch (error) {
    throw new Error(error.errors[0].message)
  }
}
