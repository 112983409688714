import { Details, Name, PublishDetails, YextId } from '../UserGroupCell.styles'

const UserGroupsDetails = ({ name, yextId }) => {
  const lastPublished = 'Last published: 4 hours ago'
  const publisher = 'by @testedevaccount'
  const location = 'Location #'

  return (
    <Details>
      <Name>{name}</Name>
      <YextId>
        {location} {yextId}
      </YextId>
      {/* <hr />
      <PublishDetails>
        <span>{lastPublished}</span>
        <br />
        <span>{publisher}</span>
      </PublishDetails> */}
    </Details>
  )
}
export default UserGroupsDetails
