const contentful = require('contentful')
const contentfulCma = require('contentful-management')

export const client = contentful.createClient({
  space: process.env.CONTENTFUL_SPACE,
  accessToken: process.env.CONTENTFUL_TOKEN_PREVIEW,
  environment: process.env.CONTENTFUL_ENV,
  host: 'preview.contentful.com',
})

export const cma = contentfulCma.createClient({
  accessToken: process.env.CONTENTFUL_CMD,
  environment: process.env.CONTENTFUL_ENV,
})
