import CloseX from '../../../static/images/close-btn.png'
import BackArrow from '../../../static/images/back-arrow.png'

const SideMenuHeader = () => {
  return (
    <>
      <div className="flex pb-8">
        <img src={BackArrow} alt={'back button'} />
        <span className="pl-4 text-sm text-left text-black col">Back</span>
        <img src={CloseX} alt={'close button'} />
      </div>
      <div className="pb-8 text-sm font-bold text-left text-black uppercase">
        {'Hours of Operation'}
      </div>
    </>
  )
}
export default SideMenuHeader
