import UserGroupsCard from '../UserGroupsCard/UserGroupsCard'
import { Fragment } from 'react'
import { GridItem } from '../UserGroupCell.styles'

const Grid = ({ imgSrc, groups, searchString, sortSelection }) => {
  let groupsInput = groups

  function dynamicSort(property) {
    return function (a, b) {
      return a[property].localeCompare(b[property])
    }
  }

  if (searchString != '') {
    const filteredGroups = groupsInput.filter((group) => {
      if (
        group.name.toLowerCase().includes(searchString.toLowerCase()) ||
        group.yextId.toString().includes(searchString)
      )
        return group
    })
    groupsInput = filteredGroups
  }

  if (sortSelection == 'Location') {
    groupsInput.sort(function (a, b) {
      return a.yextId - b.yextId
    })
  } else {
    // default sorting by name
    groupsInput.sort(dynamicSort('name'))
  }

  return (
    <Fragment>
      {groupsInput
        ? groupsInput.map((group, index) => {
            return (
              <GridItem key={index}>
                <UserGroupsCard imgSrc={imgSrc} group={group} />
              </GridItem>
            )
          })
        : 'welcome'}
    </Fragment>
  )
}

export default Grid
