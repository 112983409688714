import React, { useEffect } from 'react'
import styled from 'styled-components'
import styles from './EmergencyBanner.style'

const StyleSection = styled.section`
  ${styles};
`

export const EmergencyBanner = ({ text }) => {
  useEffect(() => {
    let pathNames = ['/dashboard', '/users', '/log']
    if (pathNames.includes(window.location.pathname)) {
      document.querySelector('#emergency-banner-easyeditor').style.cssText +=
        'position: unset'
    }
  }, [])
  return (
    <StyleSection>
      <div id="emergency-banner-easyeditor" className="banner">
        <p>{text}</p>
      </div>
    </StyleSection>
  )
}
