import {
  Form,
  Label,
  TextField,
  FieldError,
  SelectField,
  // Submit,
} from '@redwoodjs/forms'
//import '../../../node_modules/rc-tabs/assets/index.css'

import {
  ApplyToAllRow,
  DayRow,
  Monday,
  Schedule,
  Table,
} from './HoursForm.styles'

const HoursTab = ({ schedule }) => {
  const {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    // sunday,
  } = schedule

  const timeRegEx = '/\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))/gm'
  const validationParams = {
    required: true,
    pattern: {
      value: timeRegEx,
    },
  }
  const onSubmit = (data) => console.log(data)

  return (
    <Form onSubmit={onSubmit}>
      <Table>
        <Schedule>
          <Monday name="Monday">
            <td>
              <Label className="pr-2 text-left">{'Mon: '}</Label>
              <SelectField className="border border-black w-80 h-30">
                <option defaultValue="open">Open</option>
                <option value="closed">Closed</option>
              </SelectField>
            </td>
            <td>
              <Label className="px-3 text-transparent">{'.'}</Label>
              <TextField
                name="mondayStart"
                className="text-center placeholder-black border border-black input w-80 h-30"
                errorClassName="input-error"
                placeholder={monday.openIntervals[0].start + 'am'}
                validation={validationParams}
              />
              <br />
              <FieldError name="mondayStart" className="error-message" />
            </td>
            <td>
              <Label className="px-3">{'to'}</Label>
              <TextField
                name="mondayEnd"
                className="text-center placeholder-black border border-black input w-80 h-30"
                errorClassName="input-error"
                placeholder={monday.openIntervals[0].end + 'pm'}
                validation={validationParams}
              />
              <br />
              <FieldError name="mondayEnd" className="error-message" />
            </td>
          </Monday>
          <ApplyToAllRow name="ApplyToAll">
            <td>
              <Label className="float-right -mt-5 underline text-seaweed">
                {'Apply to All'}
              </Label>
            </td>
          </ApplyToAllRow>
          <DayRow name="Tuesday">
            <td>
              <Label className="pr-2 text-left">{'Tues: '}</Label>
              <SelectField className="border border-black w-80 h-30">
                <option defaultValue="open">Open</option>
                <option value="closed">Closed</option>
              </SelectField>
            </td>
            <td>
              <Label className="px-3 text-transparent">{'.'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={tuesday.openIntervals[0].start + 'am'}
                name="tuesdayStart"
                validation={validationParams}
              />
              <br />
              <FieldError name="tuesdayStart" className="error-message" />
            </td>
            <td>
              <Label className="px-3">{'to'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={tuesday.openIntervals[0].end + 'pm'}
                name="tuesdayEnd"
                validation={validationParams}
              />
              <br />
              <FieldError name="tuesdayEnd" className="error-message" />
            </td>
          </DayRow>
          <DayRow name="Wednesday">
            <td>
              <Label className="pr-2 text-left">{'Wed: '}</Label>
              <SelectField className="border border-black w-80 h-30">
                <option defaultValue="open">Open</option>
                <option value="closed">Closed</option>
              </SelectField>
            </td>
            <td>
              <Label className="px-3 text-transparent">{'.'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={wednesday.openIntervals[0].start + 'am'}
                name="wednesdayStart"
                validation={validationParams}
              />
              <br />
              <FieldError name="wednesdayStart" className="error-message" />
            </td>
            <td>
              <Label className="px-3">{'to'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={wednesday.openIntervals[0].end + 'pm'}
                name="wednesdayEnd"
                validation={validationParams}
              />
              <br />
              <FieldError name="wednesdayEnd" className="error-message" />
            </td>
          </DayRow>
          <DayRow name="Thursday">
            <td>
              <Label className="text-left" style={{ paddingRight: '0.4rem' }}>
                {'Thurs:'}
              </Label>
              <SelectField className="border border-black w-80 h-30">
                <option defaultValue="open">Open</option>
                <option value="closed">Closed</option>
              </SelectField>
            </td>
            <td>
              <Label className="px-3 text-transparent">{'.'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={thursday.openIntervals[0].start + 'am'}
                name="thursdayStart"
                validation={validationParams}
              />
              <br />
              <FieldError name="thursdayStart" className="error-message" />
            </td>
            <td>
              <Label className="px-3">{'to'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={thursday.openIntervals[0].end + 'pm'}
                name="thursdayEnd"
                validation={validationParams}
              />
              <br />
              <FieldError name="thursdayEnd" className="error-message" />
            </td>
          </DayRow>
          <DayRow name="Friday">
            <td>
              <Label className="text-left" style={{ paddingRight: '1.33rem' }}>
                {'Fri: '}
              </Label>
              <SelectField className="border border-black w-80 h-30">
                <option defaultValue="open">Open</option>
                <option value="closed">Closed</option>
              </SelectField>
            </td>
            <td>
              <Label className="px-3 text-transparent">{'.'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={friday.openIntervals[0].start + 'am'}
                name="fridayStart"
                validation={validationParams}
              />
              <br />
              <FieldError name="fridayStart" className="error-message" />
            </td>
            <td>
              <Label className="px-3">{'to'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={friday.openIntervals[0].end + 'pm'}
                name="fridayEnd"
                validation={validationParams}
              />
              <br />
              <FieldError name="fridayEnd" className="error-message" />
            </td>
          </DayRow>
          <DayRow name="Saturday">
            <td>
              <Label className="text-left" style={{ paddingRight: '1.1rem' }}>
                {'Sat: '}
              </Label>
              <SelectField className="border border-black w-80 h-30">
                <option defaultValue="open">Open</option>
                <option value="closed">Closed</option>
              </SelectField>
            </td>
            <td>
              <Label className="px-3 text-transparent">{'.'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={saturday.openIntervals[0].start + 'am'}
                name="saturdayStart"
                validation={validationParams}
              />
              <br />
              <FieldError name="saturdayStart" className="error-message" />
            </td>
            <td>
              <Label className="px-3">{'to'}</Label>
              <TextField
                className="text-center placeholder-black border border-black w-80 h-30"
                type="text"
                placeholder={saturday.openIntervals[0].end + 'pm'}
                name="saturdayEnd"
                validation={validationParams}
              />
              <br />
              <FieldError name="saturdayEnd" className="error-message" />
            </td>
          </DayRow>
          <DayRow name="Sunday">
            <td>
              <Label className="text-left" style={{ paddingRight: '0.85rem' }}>
                {'Sun: '}
              </Label>
              <SelectField className="border border-black w-80 h-30">
                <option defaultValue="closed">Closed</option>
                <option value="open">Open</option>
              </SelectField>
            </td>
            <td>
              <Label className="px-3 text-transparent">{'.'}</Label>
              <TextField
                className="text-center border placeholder-grey border-grey w-80 h-30"
                disabled
                type="text"
                placeholder={'7:30am'}
                name="sundayStart"
                validation={validationParams}
              />
              <br />
              <FieldError name="sundayStart" className="error-message" />
            </td>
            <td>
              <Label className="px-3">{'to'}</Label>
              <TextField
                className="text-center border placeholder-grey border-grey w-80 h-30"
                disabled
                type="text"
                placeholder={'6:30pm'}
                name="sundayEnd"
                validation={validationParams}
              />
              <br />
              <FieldError name="sundayEnd" className="error-message" />
            </td>
          </DayRow>
        </Schedule>
      </Table>
      {/*<Submit className="button">Save</Submit> */}
    </Form>
  )
}

export default HoursTab
