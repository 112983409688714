import moment from 'moment'
import * as _ from 'lodash'

/**
 * Parse an array containing data for new and old hours into
 * a usable array format to display.
 *
 * @param {array} arr object in the form of day=saturday, startOld=10:00, endOld=15:00, startNew=11:00, endNew=15:00
 * @param {number} endLimit substring end limit
 * @param {string} keyword keyword to match in substring
 */
const parseDayTimesToArray = (arr, endLimit, keyword) => {
  const tempArray = []

  let i = 0
  while (i < arr.length) {
    let currentVal = arr[i]
    currentVal = currentVal.trim()
    // parse data only related to changes in operation hours
    const splitItem = currentVal.split(',')
    if (currentVal.substring(0, endLimit) === keyword) {
      if (splitItem[0] !== '') {
        const out = {}
        splitItem.forEach((i) => {
          // add hour key value pair
          const splitKVPair = i.split('=')
          out[splitKVPair[0].trim()] = splitKVPair[1].trim()
        })
        tempArray.push(out)
      }
    }
    i++
  }
  return tempArray
}

/**
 * If props contains data where operation hours or additional info text
 * were changed, this function parses that string and assigns it to local state
 * for rendering.
 */
export const parseUserActionLogChanges = (data, delimeter, id) => {
  let out = {}
  // the entry is a giant string delimited by '-'.
  // split the string into an array
  let splitdata = data.split(delimeter)

  // loop through the array and remove whitespaces on either side of each item
  splitdata = splitdata.map((item) => item.trim())

  const indexOfHoursStart = splitdata.indexOf('$hours$')
  const indexOfHoursEnd = splitdata.indexOf('$/hours$')
  const indexOfExtendedHoursStart = splitdata.indexOf('$extendedHours$')
  const indexOfExtendedHoursEnd = splitdata.indexOf('$/extendedHours$')
  const indexOfOriginalHoursStart = splitdata.indexOf('$originalHours$')
  const indexOfOriginalHoursEnd = splitdata.indexOf('$/originalHours$')
  const indexOfOriginalExtendedHoursStart = splitdata.indexOf(
    '$originalExtendedHours$'
  )
  const indexOfOriginalExtendedHoursEnd = splitdata.indexOf(
    '$/originalExtendedHours$'
  )

  let arrayThatOnlyContainsModifiedOriginalHours = []
  if (indexOfHoursStart !== -1 && indexOfHoursEnd !== -1) {
    // return an array with data only related to modified regular hours
    arrayThatOnlyContainsModifiedOriginalHours = splitdata.slice(
      indexOfHoursStart + 1,
      indexOfHoursEnd
    )
  }

  let arrayThatOnlyContainsModifiedExtendedHours = []
  if (indexOfExtendedHoursStart !== -1 && indexOfExtendedHoursEnd !== -1) {
    // return an array with data only related to modified regular  extended hours
    arrayThatOnlyContainsModifiedExtendedHours = splitdata.slice(
      indexOfExtendedHoursStart + 1,
      indexOfExtendedHoursEnd
    )
  }

  let arrayThatOnlyContainsOriginalOriginalHours = []
  if (indexOfOriginalHoursStart !== -1 && indexOfOriginalHoursEnd !== -1) {
    // return an array with data only related to modified regular hours
    arrayThatOnlyContainsOriginalOriginalHours = splitdata.slice(
      indexOfOriginalHoursStart + 1,
      indexOfOriginalHoursEnd
    )
  }
  let arrayThatOnlyContainsOriginalExtendedHours = []

  if (
    indexOfOriginalExtendedHoursStart !== -1 &&
    indexOfOriginalExtendedHoursEnd !== -1
  ) {
    // return an array with data only related to modified regular  extended hours
    arrayThatOnlyContainsOriginalExtendedHours = splitdata.slice(
      indexOfOriginalExtendedHoursStart + 1,
      indexOfOriginalExtendedHoursEnd
    )
  }

  if (
    arrayThatOnlyContainsModifiedOriginalHours &&
    arrayThatOnlyContainsModifiedOriginalHours.length > 0
  ) {
    // parse the regular hours and set them to local state
    const tempArrayRegularHours = parseDayTimesToArray(
      arrayThatOnlyContainsModifiedOriginalHours,
      3,
      'day'
    )
    // setModifiedHoursTable(tempArrayRegularHours)
    out['modifiedHoursTable'] = tempArrayRegularHours
  }

  if (
    arrayThatOnlyContainsModifiedExtendedHours &&
    arrayThatOnlyContainsModifiedExtendedHours.length > 0
  ) {
    // parse the regular extended hours and set them to local state
    const tempArrayExtendedHours = parseDayTimesToArray(
      arrayThatOnlyContainsModifiedExtendedHours,
      3,
      'day'
    )
    // setModifiedExtendedHoursTable(tempArrayExtendedHours)
    out['modifiedExtendedHoursTable'] = tempArrayExtendedHours
  }

  if (
    arrayThatOnlyContainsOriginalOriginalHours &&
    arrayThatOnlyContainsOriginalOriginalHours.length > 0
  ) {
    // parse the regular hours and set them to local state
    const tempArrayRegularHoursOriginal = parseDayTimesToArray(
      arrayThatOnlyContainsOriginalOriginalHours,
      6,
      'orgDay'
    )
    // setOriginalHours(tempArrayRegularHoursOriginal)
    out['originalHours'] = tempArrayRegularHoursOriginal
  }

  if (
    arrayThatOnlyContainsOriginalExtendedHours &&
    arrayThatOnlyContainsOriginalExtendedHours.length > 0
  ) {
    // parse the regular extended hours and set them to local state
    const tempArrayExtendedHoursOriginal = parseDayTimesToArray(
      arrayThatOnlyContainsOriginalExtendedHours,
      6,
      'orgDay'
    )
    // setOriginalExtendedHours(tempArrayExtendedHoursOriginal)
    out['originalExtendedHours'] = tempArrayExtendedHoursOriginal
  }

  const indexOfYext = splitdata.indexOf('$yextid$')

  let i = 0
  while (i < splitdata.length) {
    let currentVal = splitdata[i]
    currentVal = currentVal.trim()

    // parse data only related to the old additional text message
    if (currentVal === '$oldmsg$') {
      // setOldAdditionalText(splitdata[i + 1])
      out['additionalText'] = splitdata[i + 1]
    }

    if (currentVal === '$oldExtendedmsg$') {
      // setOldExtendedAdditionalText(splitdata[i + 1])
      out['oldExtendedAdditionalText'] = splitdata[i + 1]
    }

    // parse data only related to the new additional text message
    if (currentVal === '$newmsg$') {
      // setModifiedAdditionalText(splitdata[i + 1])
      out['modifiedAdditionalText'] = splitdata[i + 1]
    }

    if (currentVal === '$newExtendedmsg$') {
      // setModifiedExtendedAdditionalText(splitdata[i + 1])
      out['modifiedExtendedAdditionalText'] = splitdata[i + 1]
    }

    // parse data only related to the old haveExtendedHours
    if (currentVal === '$originalHaveExtendedHours$') {
      out['oldHaveExtendedHours'] = splitdata[i + 1]
    }

    // parse data only related to the new haveExtendedHours
    if (currentVal === '$haveExtendedHours$') {
      out['modifiedHaveExtendedHours'] = splitdata[i + 1]
    }

    if (indexOfYext !== -1)
      if (currentVal === '$yextid$' && indexOfYext !== -1) {
        // setYextId(splitdata[i + 1])
        out['yextId'] = splitdata[i + 1]
      }

    i++
  }
  if (!_.isEmpty(out)) out['id'] = id
  return out
}

/**
 * Extract old and modified emergency banners and assign them to state vars.
 */
export const formatEmergencyBanner = (data, delimeter, id) => {
  const out = {}
  const splitdata = data.split(delimeter)
  const indexOfOldMessage = splitdata.indexOf('$oldEmergencyBanner$')
  const indexOfNewMessage = splitdata.indexOf('$modifiedEmergencyBanner$')
  const indexOfYext = splitdata.indexOf('$yextid$')

  if (indexOfOldMessage !== -1)
    out['oldEmergencyBanner'] = splitdata[indexOfOldMessage + 1]
  if (indexOfNewMessage !== -1)
    out['modifiedEmergencyBanner'] = splitdata[indexOfNewMessage + 1]
  if (indexOfYext !== -1) out['yextId'] = splitdata[indexOfYext + 1]

  if (!_.isEmpty(out)) out['id'] = id
  return out
}

/**
 * Parse logs into yext format for updating.
 *
 * @param {Array} allLogsToUpdate Array of unparsed logs
 */
export const prepareYextDataForUpdate = (allLogsToUpdate) => {
  const arrayOfOutputDataObjects = []

  allLogsToUpdate.forEach((logItem) => {
    let outputRegularHours = {}
    if (
      logItem.originalHours &&
      logItem.originalHours.length > 0 &&
      logItem.modifiedHoursTable &&
      logItem.modifiedHoursTable.length > 0
    ) {
      // transform modified hours into yext format
      outputRegularHours = prepareHoursToUpdate(
        logItem.originalHours,
        logItem.modifiedHoursTable
      )
    }
    let outputExtendedHours = {}
    if (
      logItem.originalExtendedHours &&
      logItem.originalExtendedHours.length > 0 &&
      logItem.modifiedExtendedHoursTable &&
      logItem.modifiedExtendedHoursTable.length > 0
    ) {
      // transform modified extended hours into yext format
      outputExtendedHours = prepareHoursToUpdate(
        logItem.originalExtendedHours,
        logItem.modifiedExtendedHoursTable
      )
    }
    const data = {}

    // if any data including hours, extended hours, emergency banner, additional text
    // or extended additional text was modified, append it to outgoing data object
    if (!_.isEmpty(outputRegularHours)) data['hours'] = outputRegularHours
    if (!_.isEmpty(outputExtendedHours))
      data['c_extendedHours'] = outputExtendedHours

    if (
      logItem.modifiedHaveExtendedHours !== '' &&
      typeof logItem.modifiedHaveExtendedHours !== 'undefined' &&
      logItem.modifiedHaveExtendedHours === 'false'
    ) {
      data['c_extendedHours'] = null
    }

    if (
      logItem.modifiedAdditionalText !== '' &&
      typeof logItem.modifiedAdditionalText !== 'undefined'
    )
      data['additionalHoursText'] = logItem.modifiedAdditionalText
    if (
      logItem.modifiedEmergencyBanner !== '' &&
      typeof logItem.modifiedEmergencyBanner !== 'undefined'
    )
      data['c_websiteEmergencyBanner'] = logItem.modifiedEmergencyBanner
    if (
      logItem.modifiedExtendedAdditionalText !== '' &&
      typeof logItem.modifiedExtendedAdditionalText !== 'undefined'
    )
      data['c_extendedAdditionalHoursText'] =
        logItem.modifiedExtendedAdditionalText

    arrayOfOutputDataObjects.push({
      data,
      id: logItem.id,
      yextId: logItem.yextId,
    })
  })

  return arrayOfOutputDataObjects
}

/**
 * Compare original hours of operation and modified hours of operation and
 * construct an array that will hold the final values for db updating.
 */
export const prepareHoursToUpdate = (orgHours, table) => {
  const outputHours = {}

  let tempOriginalHours = orgHours

  // copy modified hours to output object
  for (let i = 0; i < table.length; i++) {
    for (let j = 0; j < tempOriginalHours.length; j++) {
      if (tempOriginalHours[j].orgDay === table[i].day) {
        tempOriginalHours[j].isClosed = table[i].isClosedNew
        tempOriginalHours[j].orgStart = table[i].startNew
        tempOriginalHours[j].orgEnd = table[i].endNew
        break
      }
    }
  }

  // loop through original hours and assign values to output obj
  tempOriginalHours.forEach((item) => {
    const { orgDay, orgStart, orgEnd, isClosed } = item

    outputHours[`${orgDay}`] = {
      openIntervals: [
        {
          start: orgStart,
          end: orgEnd,
        },
      ],
      isClosed: isClosed == 'true' ? true : false,
    }
  })

  return outputHours
}
