/* eslint-disable no-unused-vars */
import { AuthProvider } from '@redwoodjs/auth'
// import { Auth0Client } from '@auth0/auth0-spa-js'
import ReactDOM from 'react-dom'
import { RedwoodProvider, FatalErrorBoundary } from '@redwoodjs/web'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import { TinaProvider, TinaCMS } from 'tinacms'
import { MarkdownFieldPlugin, HtmlFieldPlugin } from 'react-tinacms-editor'
import * as Msal from 'msal'
import { navigate, routes } from '@redwoodjs/router'
import { BrowserRouter } from 'react-router-dom'
import { ModalProvider } from './context/ModalContext'
import Store from '../Store'
import Routes from 'src/Routes'
import { Link } from 'react-router-dom'

import './scaffold.css'
import './index.css'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-114686308-6', { debug: false })
ReactGA.pageview(window.location.pathname + window.location.search)
//const cms = new TinaCMS({ sidebar: true })

let cms = new TinaCMS({
  enabled: false,
  sidebar: true,
})

cms.fields.add({
  name: 'Link',

  Component({ input, meta, field }) {
    return (
      <div>
        <div className="sidebarLink">
          <Link
            to={{
              pathname: field.linkUrl,
              state: field.group,
            }}
          >
            {field.linkText}
          </Link>
        </div>
      </div>
    )
  },
})

function HowdyButton() {
  return (
    <span onClick={() => alert('Good day to ya')}>Website Status - WIP</span>
  )
}

export const HowdyWidget = {
  __type: 'toolbar:widget',
  name: 'howdy',
  weight: 5,
  component: HowdyButton,
}

cms.plugins.add(MarkdownFieldPlugin)
cms.plugins.add(HtmlFieldPlugin)
cms.plugins.add(HowdyWidget)

// function isEditablePage() {
//   if (
//     location.href.match('.*/website-page/hours$') === null &&
//     location.href.match('.*/website-page/about$') === null
//   ) {
//     cms = new TinaCMS({
//       enabled: false,
//       sidebar: false,
//       toolbar: { hidden: true },
//     })
//   }
// }

// isEditablePage()

function deactivateTinaCMS() {
  cms = new TinaCMS({
    enabled: false,
    sidebar: false,
    toolbar: { hidden: true },
  })
}

function notIndexPage() {
  const DASHBOARD_URL = location.href.match(/dashboard/)
  const HOURS_OF_OPERATION_URL = location.href.match(/hoursOfOperation/)
  const STAFF_URL = location.href.match(/staff/)

  if (DASHBOARD_URL || HOURS_OF_OPERATION_URL || STAFF_URL) {
    deactivateTinaCMS()
  }
}

notIndexPage()

// const auth0 = new Auth0Client({
//   domain: process.env.AUTH0_DOMAIN,
//   client_id: process.env.AUTH0_CLIENT_ID,
//   redirect_uri: 'http://localhost:8910',
//   cacheLocation: 'localstorage',
//   audience: process.env.AUTH0_AUDIENCE,
// })
// const authClient = new Msal.UserAgentApplication(msalConfig)

// const makeLogin = () => {
//   authClient
//     .loginPopup(loginRequest)
//     .then((loginResponse) => {
//       console.log('loginResponse', loginResponse)
//       // navigate(routes.admin())
//     })
//     .catch(function (error) {
//       console.log(error)
//     })
// }

// const getMSALAccount = async function (authClient) {
//   const data = await authClient.getAccount()
//   console.log('data', data)
//   return data
//   return authClient.getAccount() || 'no data returned'
// }

// const MSALAuthClient = {
//   type: 'custom',
//   client: authClient,
//   login: () => makeLogin(),
//   logout: () => authClient.logout(),
//   getToken: getMSALAccount(authClient),
//   getUserMetadata: getMSALAccount(authClient),
// }

ReactDOM.render(
  <Store>
    <ModalProvider>
      <FatalErrorBoundary page={FatalErrorPage}>
        <BrowserRouter>
          <RedwoodProvider>
            <TinaProvider cms={cms}>
              <Routes />
            </TinaProvider>
          </RedwoodProvider>
        </BrowserRouter>
      </FatalErrorBoundary>
    </ModalProvider>
  </Store>,
  document.getElementById('redwood-app')
)
