import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import { useState, useEffect, useCallback, Fragment } from 'react'
import UserGroupsCellRest from '../../components/UserGroupsCellRest'
// import UserGroupsCell from '../../components/UserGroupsCell'

const AdminPage = ({ userid }) => {
  const [intialData, setInitialData] = useState({})
  const [isLoading, setLoadingStatus] = useState(true)

  /**
   * Load initial data from YEXT.
   */
  const loadingInitialContent = useCallback(async () => {
    fetch(
      `https://nva-appno-api-dev.azure-api.net/NVA-APPNO-publisher-DEV/EasyEditorYext?yextID=1803`
    )
      .then((data) => data.json())
      .then((response) => {
        setInitialData({
          yextData: response,
        })

        setLoadingStatus(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    loadingInitialContent()
  }, [loadingInitialContent])

  return !isLoading ? (
    <UpdatedMainLayout>
      {/* <UserGroupsCell data={intialData} email={user.email} /> */}
      <UserGroupsCellRest data={intialData} id={userid} />
    </UpdatedMainLayout>
  ) : (
    <Fragment>xloading...</Fragment>
  )
}

export default AdminPage
