import styled from 'styled-components'
import tw from 'twin.macro'

export const HoursOfOperation = styled.div`
  ${tw`h-screen -mt-2 bg-white row`};
`
export const MainContentWrapper = styled.div`
  ${tw`text-black bg-white col`};
`
export const SideMenuWrapper = styled.div`
  bottom: 2rem;
  box-shadow: 0px 2px 15px -10px rgba(0, 0, 0, 0.5);
  ${tw`relative bg-white`};
`
