import { useState, useEffect } from 'react'
import MainLayout from 'src/layouts/MainLayout'
import WebsitePageHoursEditForm from '../../pages/WebsitePagePage/WebsitePageHoursEditForm'
import WebsiteEmergencyBannerEditForm from '../../pages/WebsitePagePage/WebsiteEmergencyBannerEditForm'
import WebsitePageMain from '../../pages/WebsitePagePage/WebsitePageMain'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
} from 'react-router-dom'
import WebsitePagePage from '../../pages/WebsitePagePage'

export const QUERY = gql`
  query FIND_USER_BY_EMAIL($email: String!) {
    userEmail: userEmail(email: $email) {
      id
      email
      name
      userId
      groups {
        id
        name
        yextId
        siteId
        siteURL
      }
      role {
        id
        name
      }
    }
  }
`

export const Loading = () => <div>xLoading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => <div>Error: {error.message}</div>

export const Success = ({ userEmail, routingInfo }) => {
  let { siteId, pageName } = useParams()

  let yextId = userEmail.groups.filter((group) => siteId == group.yextId)

  let selectedSite =
    routingInfo && routingInfo.location && routingInfo.location.state

  const [intialData, setInitialData] = useState({})
  const [isLoading, setLoadingStatus] = useState(true)

  useEffect(() => {
    async function loadingInitialContect() {
      fetch(
        `https://nva-appno-api-dev.azure-api.net/NVA-APPNO-publisher-DEV/EasyEditorYext?yextID=1803`
      )
        .then((data) => data.json())
        .then((response) => {
          setInitialData({
            yextData: response,
          })

          setLoadingStatus(false)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    loadingInitialContect()
  }, [])

  return yextId.length > 0 ? (
    <MainLayout
      groups={userEmail.groups}
      selectedSite={selectedSite ? selectedSite : yextId[0]}
    >
      {!isLoading ? (
        pageName && pageName == 'hours' ? (
          <WebsitePageHoursEditForm
            initialContent={intialData}
            routingInfo={routingInfo}
          />
        ) : pageName && pageName == 'about' ? (
          <WebsiteEmergencyBannerEditForm
            initialContent={intialData}
            routingInfo={routingInfo}
          />
        ) : pageName && pageName == 'staff' ? (
          <WebsiteEmergencyBannerEditForm
            initialContent={intialData}
            routingInfo={routingInfo}
          />
        ) : pageName && pageName == 'main' ? (
          <WebsitePageMain
            routingInfo={routingInfo}
            selectedSite={selectedSite ? selectedSite : yextId[0]}
          />
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </MainLayout>
  ) : (
    //<h1> No </h1>
    <Redirect
      to={{
        pathname: '/dashboard',
      }}
    />
  )
}
