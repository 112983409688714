import { SearchIcon } from '../UserGroupCell.styles'

const IconSearch = () => {
  return (
    <SearchIcon>
      <svg
        className="w-4 h-4 mt-3"
        aria-hidden="true"
        fill="#007F85"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d={
            'M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
          }
          clipRule="evenodd"
        ></path>
      </svg>
    </SearchIcon>
  )
}

export default IconSearch
