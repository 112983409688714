import UpdatedMainLayout from '../../layouts/UpdatedMainLayout/UpdatedMainLayout'
import UserActionLogsCellRest from '../../components/UserActionLogsCellRest'

const UserActionLogPage = ({ role }) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return <UserActionLogsCellRest />
}

export default UserActionLogPage
