import IconSearch from './IconSearch'
import SearchInput from './SearchInput'
import { InputWrapper } from '../UserGroupCell.styles'

const SearchBox = ({setSearchString}) => {
  return (
    <InputWrapper>
      <IconSearch />
      <SearchInput setSearchString={setSearchString}/>
    </InputWrapper>
  )
}

export default SearchBox
