/* eslint-disable camelcase */
import DummyImage from '../../static/images/dummy-image.png'
import SideMenu from '../../components/SideMenu/SideMenu'
import useFetch from '../../hooks/useFetch'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import {
  HoursOfOperation,
  MainContentWrapper,
  SideMenuWrapper,
} from './HoursOfOperationPage.styles'

// eslint-disable-next-line no-unused-vars
const HoursOfOperationPage = (account) => {
  const yextURL =
    'https://nva-appno-api-dev.azure-api.net/NVA-APPNO-publisher-DEV/EasyEditorYext?yextID=1803'
  const { loading, data, error } = useFetch(yextURL)

  if (loading) return 'Loading...'
  if (error) return 'Oops!'
  if (!loading && data) {
    const { hours, c_extendedHours } = data.response
    return (
      <UpdatedMainLayout>
        <HoursOfOperation>
          <SideMenuWrapper>
            <SideMenu mainHours={hours} secondaryHours={c_extendedHours} />
          </SideMenuWrapper>
          <MainContentWrapper>
            <img
              className="w-full h-auto -mt-10"
              src={DummyImage}
              alt="dummy image"
            />
          </MainContentWrapper>
        </HoursOfOperation>
      </UpdatedMainLayout>
    )
  }
}

export default HoursOfOperationPage
