import React from 'react'
import MaterialTable from 'material-table'

const HoursTable = ({ changes, title }) => {
  return (
    <div className="rw-segment">
      <div className="rw-modal-hours">
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            options={{
              search: false,
              filtering: false,
              paging: false,
            }}
            columns={[
              {
                title: 'Day',
                field: 'hoo_day',
                cellStyle: {
                  textTransform: 'capitalize',
                },
              },
              {
                title: 'Type',
                field: 'type',
                cellStyle: {
                  textTransform: 'capitalize',
                },
                render: (rowData) => (
                  <span>
                    {rowData.hoo_fieldName == 'status' ? 'Status' : 'Hours'}
                  </span>
                ),
              },
              {
                title: 'Previous',
                field: 'oldValue',
                cellStyle: {
                  textDecoration: 'line-through',
                },
                render: (rowData) => {
                  if (rowData.oldValue) {
                    return <span>{rowData.oldValue}</span>
                  } else {
                    return rowData.openIntervals.map((interval, key) => (
                      <p key={key}>
                        {interval.oldStart} {interval.oldStart ? '-' : ''}{' '}
                        {interval.oldEnd}
                      </p>
                    ))
                  }
                },
              },
              {
                title: 'New',
                field: 'newValue',
                cellStyle: { color: 'red' },
                render: (rowData) => {
                  if (rowData.newValue) {
                    return <span>{rowData.newValue}</span>
                  } else {
                    return rowData.openIntervals.map((interval, key) => (
                      <p key={key}>
                        {interval.newStart} - {interval.newEnd}
                      </p>
                    ))
                  }
                },
              },
            ]}
            data={changes}
            title={title}
          />
        </div>
      </div>
    </div>
  )
}

export default HoursTable
