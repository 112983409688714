import { useState, createContext } from 'react'
let ModalContext
let { Provider } = (ModalContext = createContext())

const ModalProvider = ({ children }) => {
  const [isShowing, setIsShowing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [logs, setLogs] = useState([])

  const toggle = () => {
    setIsShowing(!isShowing)
  }

  const savingPublishedChanges = (state) => {
    setIsSaving(state)
  }
  const updateLogs = (data) => {
    setLogs(data)
  }

  return (
    <Provider
      value={{
        savingPublishedChanges,
        isSaving,
        isShowing,
        toggle,
        updateLogs,
        logs,
      }}
    >
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }
