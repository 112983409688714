import Tabs, { TabPane } from 'rc-tabs'
import HoursForm from './HoursForm'
import SideMenuHeader from './SideMenuHeader/SideMenuHeader'
import '../../static/styles/tabs.css'

const SideMenu = ({ mainHours, secondaryHours }) => {
  const MAIN_HOURS_STR = 'Main Hours'
  const SEC_HOURS_STR = 'Secondary Hours'
  return (
    <>
      <SideMenuHeader />
      <Tabs defaultActiveKey="1">
        <TabPane tab={MAIN_HOURS_STR} key="1" className="text-sm">
          <HoursForm schedule={mainHours} />
        </TabPane>
        <TabPane tab={SEC_HOURS_STR} key="2" className="text-sm">
          <HoursForm schedule={secondaryHours} />
        </TabPane>
      </Tabs>
    </>
  )
}

export default SideMenu
